@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    color-scheme: only light;
    --offset: 0.25rem;
}
html,
body {
    scroll-behavior: smooth;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    background-color: #ffffff !important;
    overscroll-behavior: none;
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
}

ul {
    list-style-type: none;
}
.commonVideoComponentContainer {
    /* Hide video controls for all video elements */
    video::-webkit-media-controls,
    video::-webkit-media-controls-panel,
    video::-webkit-media-controls-play-button,
    video::-webkit-media-controls-volume-slider,
    video::-webkit-media-controls-mute-button,
    video::-webkit-media-controls-timeline,
    video::-webkit-media-controls-current-time-display,
    video::-webkit-media-controls-start-playback-button,
    video::-webkit-media-controls-overlay-play-button,
    video::-moz-media-controls {
        display: none !important;
        pointer-events: none;
    }

    @supports (-webkit-appearance: none) {
        video::-webkit-media-controls,
        video::-webkit-media-controls-panel {
            display: none !important;
        }
    }
}

#storyAvatarComponent {
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    overflow: hidden;
}

#story_background_video {
    overflow: hidden;
}

.top-1-1rem {
    top: 1.1rem;
}

.-z-1 {
    z-index: -4;
}

.set-layout-view {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.set-layout-view-onboarding {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.quiz-layout-button {
    width: 100%;
}

.set-layout-container {
    width: 100vw;
    // height: 100vh;
    margin: 0;
    padding: 0;
    background-color: white !important;
}

.set-layout-contrainer-view {
    // height: 100dvh;
}
.personalize-container {
    // height: 100vh;
}

.set-layout-view-2 {
    width: 480px;
    height: 100%;
    background-color: white;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.personalize-view {
    width: 480px;
    height: 100%;
    background-color: white !important;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.modalDialog {
    overflow-x: auto;
    overflow-y: auto;
    position: fixed;
    top: 0;
    z-index: 1000;
    width: inherit;
    height: 100%;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    transition-delay: 0s;
}

.modalDialogShadow {
    background: rgba(0, 0, 0, 0.5);
}

.modalDocument {
    width: auto;
    display: flex;
    margin: 0.5rem;
    position: relative;
    align-items: center;
    pointer-events: none;
    min-height: calc(100% - 4rem);
}

.modalDocumentWhite {
    background: rgba(255, 255, 255, 1) !important;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;

    border-bottom: 24px solid #f4f4f4;
}

.modalContent {
    margin: 0;
    width: inherit;
    height: inherit;
    pointer-events: auto;
    outline: 0;
    box-sizing: border-box;
}

.bottomSheetDialog {
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;
    bottom: 0;
    z-index: 1400;
    width: inherit;
    height: 100%;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    transition-delay: 0s;
    background: rgba(0, 0, 0, 0.5);
}

.bottomSheetDocument {
    position: fixed;
    bottom: 0;
    width: inherit;
    background-color: white !important;
    // border-radius: 15px 15px 0 0;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    align-items: center;
    box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.16);
}

// has media queries - please check the media queries block below
.bg-elipse {
    max-width: 768px;
    width: 100%;
    height: 20%;
    border-radius: 100% 100% 0 0;
    background-color: #fcf6d7 !important;
    top: 45%;
    position: absolute;
}

// has media queries - please check the media queries block below
.bg-box {
    max-width: 768px;
    width: 100%;
    height: 51%;
    top: 64%;
    position: absolute;
    background-color: #fcf6d7 !important;
}

.react-player {
    width: auto !important;
    height: auto !important;
}

.Toastify__toast-container {
    padding: 1rem !important;
    font-family:
        "Open Sans",
        sans-serif,
        -apple-system,
        BlinkMacSystemFont !important;
}

.Toastify__toast {
    border-radius: 1rem !important;
}

.Toastify__toast-container--bottom-center {
    position: relative;
    bottom: 5rem;
}

.Toastify__close-button--light {
    opacity: 0.5 !important;
}

.Toastify__close-button > svg {
    width: 1.2rem !important;
    height: 1.2rem !important;
}

.color-aub {
    color: #270f36 !important;
}

.avatar {
    max-width: none !important;
}

.confetti {
    margin-left: auto;
    margin-right: auto;
}

.meme-skip-container {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
}
.star-animation-div-mobile {
    display: none;
}
.star-animation-div-web {
    z-index: 100;
    position: absolute;
    top: 0%;
}

@media only screen and (max-width: 480px) {
    .set-layout-contrainer-view {
        width: 100vw;
        // height: 100dvh;
        // background-color: white !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .personalize-container-dvh {
        width: 100vw;
        height: 100dvh;
        background-color: white !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .personalize-container-vh {
        width: 100vw;
        height: 100vh;
        background-color: white !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .star-animation-div-mobile {
        display: unset;
        z-index: 100;
        position: absolute;
        top: 0%;
    }
    .star-animation-div-web {
        display: none;
    }
    .set-layout-view-2 {
        width: 100%;
        height: 100%;
        background-color: white;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    .personalize-view {
        width: 100%;
        height: 100%;
        background-color: white !important;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.horizontal-center-fixed {
    left: 0%;
}

@media (min-width: 760px) {
    .set-layout-contrainer-view {
        // aspect-ratio: 9 / 16;
    }
    .horizontal-center-fixed {
        left: 50%;
        transform: translate(-50%) !important;
    }
    .set-layout-view-2 {
        // width: 100%;
        width: 480px !important;
        // width: calc(100vh * 9 / 16) !important;
    }
    .quiz-header-container {
        width: 480px !important;
        // width: 400px !important;
        // width: calc(100vh * 9 / 16) !important;
    }
    #keyboard_context_provider_stories_block_child {
        width: calc(100vh * 9 / 16) !important;
    }
}

// media queries - make sure they always come at the end of the file to prevent overwrites
@media only screen and (min-width: 600px) {
    .set-layout-view {
        width: 100%;
        height: 100%;
        background-color: white !important;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .set-layout-view-onboarding {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .quiz-layout-button {
        width: 100%;
    }

    .set-layout-container {
        width: 100vw;
        // height: 100vh;
        background-color: white !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .bg-elipse {
        //max-width: 600px;
        width: 100%;
        height: 30%;
        border-radius: 100% 100% 0 0;
        background-color: #fcf6d7 !important;
        top: 45%;
        position: absolute;
    }

    .bg-box {
        //max-width: 600px;
        width: 100%;
        height: 41%;
        top: 74%;
        position: absolute;
        background-color: #fcf6d7 !important;
    }
}

@media only screen and (min-width: 768px) {
    .set-layout-view {
        width: 768px;
        height: 100%;
        background-color: white !important;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .set-layout-view-onboarding {
        width: 768px;
        height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .quiz-layout-button {
        width: 768px;
    }

    .set-layout-container {
        width: 100vw;
        // height: 100vh;
        background-color: white !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .bg-elipse {
        max-width: 768px;
        width: 100%;
        height: 30%;
        border-radius: 100% 100% 0 0;
        background-color: #fcf6d7 !important;
        top: 45%;
        position: absolute;
    }

    .bg-box {
        max-width: 768px;
        width: 100%;
        height: 41%;
        top: 74%;
        position: absolute;
        background-color: #fcf6d7 !important;
    }
}

// 500
body div#error {
    height: 100vh;
    position: relative;
    margin: auto;
    padding: 20px;
    z-index: 2;
}
body div#error div#box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #000;
}
body div#error div#box:before,
body div#error div#box:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 0px 1px #000;
    mix-blend-mode: multiply;
    animation: dance 2s infinite steps(1);
}
body div#error div#box:before {
    clip-path: polygon(0 0, 65% 0, 35% 100%, 0 100%);
    box-shadow: inset 0px 0px 0px 1px currentColor;
    color: #f0f;
}
body div#error div#box:after {
    clip-path: polygon(65% 0, 100% 0, 100% 100%, 35% 100%);
    animation-duration: 0.5s;
    animation-direction: alternate;
    box-shadow: inset 0px 0px 0px 1px currentColor;
    color: #0ff;
}
body div#error h3 {
    position: relative;
    font-size: 10vw;
    font-weight: 700;
    text-transform: uppercase;
    animation: blink 1.3s infinite steps(1);
}
body div#error h3:before,
body div#error h3:after {
    content: "ERROR";
    position: absolute;
    top: -1px;
    left: 0;
    mix-blend-mode: soft-light;
    animation: dance 2s infinite steps(2);
}
body div#error h3:before {
    clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
    color: #f0f;
    animation: shiftright 200ms steps(2) infinite;
}
body div#error h3:after {
    clip-path: polygon(0 100%, 100% 100%, 100% 50%, 0 50%);
    color: #0ff;
    animation: shiftleft 200ms steps(2) infinite;
}
body div#error p {
    position: relative;
    margin-bottom: 8px;
}
body div#error p span {
    position: relative;
    display: inline-block;
    font-weight: bold;
    color: #000;
    animation: blink 3s steps(1) infinite;
}
body div#error p span:before,
body div#error p span:after {
    content: "unstable";
    position: absolute;
    top: -1px;
    left: 0;
    mix-blend-mode: multiply;
}
body div#error p span:before {
    clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
    color: #f0f;
    animation: shiftright 1.5s steps(2) infinite;
}
body div#error p span:after {
    clip-path: polygon(0 100%, 100% 100%, 100% 50%, 0 50%);
    color: #0ff;
    animation: shiftleft 1.7s steps(2) infinite;
}
@-moz-keyframes dance {
    0%,
    84%,
    94% {
        transform: skew(0deg);
    }
    85% {
        transform: skew(5deg);
    }
    90% {
        transform: skew(-5deg);
    }
    98% {
        transform: skew(3deg);
    }
}
@-webkit-keyframes dance {
    0%,
    84%,
    94% {
        transform: skew(0deg);
    }
    85% {
        transform: skew(5deg);
    }
    90% {
        transform: skew(-5deg);
    }
    98% {
        transform: skew(3deg);
    }
}
@-o-keyframes dance {
    0%,
    84%,
    94% {
        transform: skew(0deg);
    }
    85% {
        transform: skew(5deg);
    }
    90% {
        transform: skew(-5deg);
    }
    98% {
        transform: skew(3deg);
    }
}
@keyframes dance {
    0%,
    84%,
    94% {
        transform: skew(0deg);
    }
    85% {
        transform: skew(5deg);
    }
    90% {
        transform: skew(-5deg);
    }
    98% {
        transform: skew(3deg);
    }
}
@-moz-keyframes shiftleft {
    0%,
    87%,
    100% {
        transform: translate(0, 0) skew(0deg);
    }
    84%,
    90% {
        transform: translate(-8px, 0) skew(20deg);
    }
}
@-webkit-keyframes shiftleft {
    0%,
    87%,
    100% {
        transform: translate(0, 0) skew(0deg);
    }
    84%,
    90% {
        transform: translate(-8px, 0) skew(20deg);
    }
}
@-o-keyframes shiftleft {
    0%,
    87%,
    100% {
        transform: translate(0, 0) skew(0deg);
    }
    84%,
    90% {
        transform: translate(-8px, 0) skew(20deg);
    }
}
@keyframes shiftleft {
    0%,
    87%,
    100% {
        transform: translate(0, 0) skew(0deg);
    }
    84%,
    90% {
        transform: translate(-8px, 0) skew(20deg);
    }
}
@-moz-keyframes shiftright {
    0%,
    87%,
    100% {
        transform: translate(0, 0) skew(0deg);
    }
    84%,
    90% {
        transform: translate(8px, 0) skew(20deg);
    }
}
@-webkit-keyframes shiftright {
    0%,
    87%,
    100% {
        transform: translate(0, 0) skew(0deg);
    }
    84%,
    90% {
        transform: translate(8px, 0) skew(20deg);
    }
}
@-o-keyframes shiftright {
    0%,
    87%,
    100% {
        transform: translate(0, 0) skew(0deg);
    }
    84%,
    90% {
        transform: translate(8px, 0) skew(20deg);
    }
}
@keyframes shiftright {
    0%,
    87%,
    100% {
        transform: translate(0, 0) skew(0deg);
    }
    84%,
    90% {
        transform: translate(8px, 0) skew(20deg);
    }
}
@-moz-keyframes blink {
    0%,
    50%,
    85%,
    100% {
        color: #000;
    }
    87%,
    95% {
        color: transparent;
    }
}
@-webkit-keyframes blink {
    0%,
    50%,
    85%,
    100% {
        color: #000;
    }
    87%,
    95% {
        color: transparent;
    }
}
@-o-keyframes blink {
    0%,
    50%,
    85%,
    100% {
        color: #000;
    }
    87%,
    95% {
        color: transparent;
    }
}
@keyframes blink {
    0%,
    50%,
    85%,
    100% {
        color: #000;
    }
    87%,
    95% {
        color: transparent;
    }
}

// worksheet-preview
.pad-16 {
    padding: 16px;
}

.preview-type {
    padding: 4px 0px;
    font-weight: 700;
    line-height: 14px;
    font-size: 14px;
    color: #270f36;
}

.preview-title {
    padding: 4px 0px;
    font-weight: 700;
    line-height: 34px;
    font-size: 28px;
    color: #270f36;
}

.height-30 {
    height: 30px;
}

.height-20 {
    height: 20px;
}

.height-16 {
    height: 16px;
}

.width-6 {
    width: 6px;
}

.preview-option-container {
    margin: 6px 8px;
    padding: 12px 8px;
    // border: 1px solid #ECECEC;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
}

.preview-option-idx-content {
    height: 24px;
    width: 24px;
    background-color: #f4f4f4;
    border-radius: 12px;
    display: grid;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
}

.preview-idx {
    margin: auto;
}

.preview-option {
    padding: 2px 8px 0px 12px;
}

.preview-question-info {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.preview-tag {
    background: #ececec;
    border-radius: 4px;
    padding: 6px;
    margin: 6px 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
}

.preview-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.preview.text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.sortable-item {
    pointer-events: auto !important;
}

.sortable-container > .sortable-item:hover {
    cursor: grab;
}

body > .sortable-item:hover {
    cursor: grabbing;
    .sort-item {
        box-shadow: inset 0 0 50px 0px rgba(0, 0, 0, 0.15);
    }
}

.chat-form .mathbf {
    font-size: 16px;
    font-family: Epilogue;
    font-weight: 600;
}

.chat-form .mathit,
.chat-form .mathnormal {
    font-size: 16px;
    font-family: Epilogue;
}

.react-mic {
    background-color: red;
    width: 100%;
}

.highlight {
    z-index: 2;
}

.feedback {
    position: fixed;
    z-index: 2;
    background: #ffffff;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.arrow-line {
    width: 50px;
    border: 0.5px solid #000;
    position: fixed;
    background-color: #000;
    z-index: 3;
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -10px;
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right: 10px solid #000;
        transform: translateY(-50%);
    }
}
.foo {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 40px !important;
    // min-height: 32px;
    min-width: 63px;
    font-size: 16px;
    line-height: 18px;
    z-index: 1;
    position: relative;
    // padding: 5px;
}

.foo1 {
    align-items: center;
    width: 100%;
    // padding: 9px 12px;
}

.foo2 {
    align-items: center;
    width: 100%;
    font-size: 14px;
    // padding: 9px 12px;
}

.react-spring-bottom-sheet {
    z-index: 5;
    position: fixed;
}

.mord {
    white-space: nowrap;
    span {
        display: inline-block;
    }
}

// carousel
.carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
    scrollbar-width: none; /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.left-arrow,
.right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: white !important;
    border: 1px solid #ddd;
}

.left-arrow {
    left: 24px;
}

.right-arrow {
    right: 24px;
}

@media (hover: none) and (pointer: coarse) {
    .left-arrow,
    .right-arrow {
        display: none;
    }
}

.two-line-ellipsis {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.onboarding-animation {
    svg {
        width: 80% !important;
    }
}
.otp-input:focus-visible {
    outline: 2px solid #8282f4;
}
.otp-input::-webkit-outer-spin-button,
.otp-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.mx-line-2 {
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* New Home page css*/

.home-wrapper {
    @apply w-full overflow-y-auto;
}
.home-banner-wrapper {
    @apply relative mb-[76px] h-[25vh] w-full;
}

.home-banner-background {
    background: linear-gradient(
        134deg,
        #fcf6d7 0%,
        rgba(252, 246, 215, 0) 100%
    );
}

.home-banner-text-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-banner-text-style {
    color: #000;
    font-family: Epilogue;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
    display: inline-block;
    width: 100%;
    font-size: 1.25rem;
}
// .home-banner-text-style > span {
//     color: rgba(27, 52, 255, 1);
// }
.home-banner-heading-wrapper {
    display: grid;
    place-items: center;
}

.home-banner-avatar-wrapper {
    @apply absolute bottom-[-40px] left-[24px] h-[80px] w-[80px] overflow-hidden rounded-[40px] bg-white;
}

.home-banner-avatar-wrapper-styles {
    @apply border-4;
    border-color: #ffde49;
}

.home-banner-bottom-asset-image {
    @apply absolute bottom-[-65px] right-[0px] h-[76px] w-[90px] opacity-50;
}

.home-page-horizontal-list-wrapper {
    @apply relative mb-[32px] h-[240px];
}
.home-page-horizontal-list-background {
    background-color: #f9f8f8;
}
.list-background {
    background-color: #f9f8f8;
}
.home-section-heading-wrapper {
    @apply grid grid-cols-[30px_1fr] gap-[10px] px-[16px] pt-[10px];
}
.home-section-heading-avatar {
    @apply h-[30px] w-[30px] overflow-hidden rounded-[4px];
}
.home-section-heading-text {
    color: var(--dark-grey, #333);
    font-size: 16px;
    font-family: Epilogue;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 260px;
    white-space: nowrap;
}
.home-section-item-wrapper {
    @apply relative h-[160px] w-[230px] overflow-hidden rounded-[8px];
    box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.16);
}

.home-section-item-background-scaffold {
    @apply absolute bottom-0 left-0 right-0 top-0;
    z-index: 40;
}

.home-section-item-label {
    @apply absolute left-[16px] top-[8px] bg-white text-xs;
    padding: 4px 4px 0px 4px;
    border-radius: 4px;
    color: #1b34ff;
    text-align: center;
    font-size: 12px;
    font-family: Epilogue;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    z-index: 70;
}

.home-section-steps-wrapper {
    @apply absolute bottom-[20px] left-[16px] z-[70] w-[100px];
}

.home-section-item-image {
    @apply absolute bottom-0 right-0 top-0 grid w-[100px] place-items-center;
}
.home-section-item-text-wrapper {
    @apply relative z-50 grid h-[100px] place-items-center pl-[16px] pt-[38px];
    // grid-template-rows: 18px 1fr;
}
.home-section-item-text-wrapper > p {
    display: grid;
    justify-self: left;
    color: #270f36;
    font-size: 14px;
    font-family: Epilogue;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
}

.home-section-item-title-wrapper {
    display: grid;
    justify-self: left;
    height: fit-content;
}
.home-section-item-title-wrapper > h5 {
    color: #270f36;
    font-size: 20px;
    font-family: Epilogue;
    font-style: normal;
    font-weight: 800;
    line-height: 25px;
    max-height: 110px;
    max-width: 160px;
    height: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.home-section-items-horizontal-list-overflow-wrapper {
    @apply h-[212px] overflow-x-auto overflow-y-hidden p-[16px];
}
.home-section-items-horizontal-list-overflow-wrapper::-webkit-scrollbar {
    width: 20px;
    width: 0;
    height: 0;
}

.home-section-items-horizontal-list-overflow-wrapper::-webkit-scrollbar-track {
    background-color: transparent;
}

.home-section-items-horizontal-list-overflow-wrapper::-webkit-scrollbar-thumb {
    @apply bg-transparent;
    border-radius: 25px;
    opacity: 0.5;
    scrollbar-gutter: 0px;
}
.home-section-items-horizontal-list-wrapper {
    @apply grid min-w-max gap-[8px];
}

.home-section-items-overflow-wrapper {
    @apply h-[192px] overflow-x-hidden overflow-y-hidden p-[16px];
}

.home-section-vertical-list-item-wrapper {
    @apply h-fit;
    background-color: #f9f8f8;
    cursor: pointer;
}
.home-section-vertical-list-item-card {
    @apply grid h-[96px] p-[8px];
    grid-template-columns: 80px 1fr;
}

.home-section-vertical-list-item-text-wrapper {
    @apply grid pl-[20px];
    align-self: center;
}

.home-section-vertical-list-item-text-wrapper > h5 {
    @apply mb-2;
    color: #000;
    font-size: 16px;
    font-family: Epilogue;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}
.home-section-vertical-list-item-text-wrapper > p {
    color: #000;
    font-size: 14px;
    font-family: Epilogue;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
}
.home-section-vertical-list-item-image-wrapper {
    background: white;
    border-radius: 10px;
    padding: 8px;
}
.home-section-vertical-list-item-expanded-area {
    @apply grid place-items-center gap-[16px] border-b-[1px] p-[16px];
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 100%;
}

.home-section-vertical-list-item-expanded-area-mobile {
    grid-template-columns: 1fr !important;
}

.home-vertical-list-section-wrapper {
    @apply relative h-fit;
}

.home-vertical-list-section-background {
    background: #f9f8f8;
}

.home-vertical-list-wrapper {
    @apply w-full;
}

.home-section-vertical-card-wrapper {
    @apply overflow-hidden px-[12px] py-[8px];
    height: 100% !important;
    width: 100% !important;
    box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.16);
}
.home-section-vertical-card-image > img {
    @apply relative z-50 m-auto h-[66px];
    object-fit: cover;
}
.home-section-vertical-card-text-wrapper {
    @apply mb-2 h-fit p-0 pt-2;
}
.home-section-vertical-card-title-wrapper {
    @apply h-fit overflow-hidden;
}
.home-section-vertical-card-title-wrapper > h5 {
    color: #000;
    font-size: 14px;
    font-family: Epilogue;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    height: 32px;
    // max-width: 90px;
}
.home-section-vertical-card-steps-wrapper {
    @apply block;
    bottom: unset;
    left: unset;
}
.level-component-wrapper {
    @apply h-[6px] w-full rounded-sm bg-white;
}

.level-component-slider {
    @apply h-[6px] rounded-sm;
    background: rgba(27, 52, 255, 1);
}

.level-component-text {
    @apply ml-[2px] mt-1;
    color: #000;
    font-size: 12px;
    font-family: Epilogue;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.scrollbar-styled::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.scrollbar-styled::-webkit-scrollbar-track {
    background-color: white !important;
    background: transparent;
    opacity: 0;
}

.scrollbar-styled::-webkit-scrollbar-thumb {
    @apply bg-gray-300;
    border-radius: 25px;
    opacity: 0.5;
    scrollbar-gutter: 0px;
}

@media screen and (max-width: 365px) {
    // .home-banner-heading-wrapper{
    //     @apply w-[90%];
    // }
    .home-banner-heading-wrapper > h3 {
        padding-left: 16px;
        padding-right: 16px;
        font-size: 16px;
    }
    .home-banner-text-wrapper {
        bottom: 50px;
    }
}

// Onboarding classnames
.onboarding-user-type-selection-card {
    @apply flex w-full items-center gap-4 rounded-[8px] bg-white;
    box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.16);
    grid-template-rows: 120px 1fr;
}

.onboarding-math-field-selection-card {
    @apply grid w-full place-items-center  gap-4 rounded-[8px] bg-white;
    box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.16);
    grid-template-rows: 1fr 0.2fr;
}

.onboarding-math-field-selection-wrapper {
    @apply m-auto grid max-w-[360px] grid-cols-2 grid-rows-2 gap-4;
}

.onboarding-splash-screen-text {
    color: var(--dark-grey, #333);
    text-align: center;
    font-family: Epilogue;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.custom-select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    text-indent: 1px;
    text-overflow: "";
    background-color: transparent;
    box-sizing: content-box;
    font-size: 18px;
    font-weight: 900;
    width: 56px;
    text-align-last: center;
}

.custom-select:focus {
    outline: none;
}

.custom-select option {
    font-size: 32px;
}

[data-rsbs-overlay],
[data-rsbs-root]:after {
    background-color: transparent !important;
}

.union-rectangle {
    // background: rgb(255, 255, 255);
    background: linear-gradient(
        0deg,
        rgba(254, 222, 73, 0.6) 0%,
        rgba(254, 222, 73, 1) 100%
    );
    // background: linear-gradient(
    //     0deg,
    //     rgba(254, 109, 73, 0.9) 0%,
    //     rgb(254, 109, 73) 100%
    // );
    border-top-right-radius: 400px 100px;
    border-top-left-radius: 1 10px;
}

// @media (prefers-color-scheme: dark) {
//     html {
//         background: white;
//         color: black;
//     }
// }

.mq-editable-field {
    display: flex;
    align-items: center;
    min-width: 30px;
    min-height: 30px;
    width: fit-content;
    height: fit-content;
}

.mq-root-block {
    display: flex !important;
    align-items: center !important;
    min-width: 30px;
    min-height: 30px;
}

.mq-focused {
    border: 1px solid #ffde49 !important;
    outline: 1px solid #ffde49 !important;
    outline-offset: 0px !important;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 30px;
    padding: 4px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #000fff;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: spinner 1s infinite linear;
}

@keyframes spinner {
    to {
        transform: rotate(1turn);
    }
}

.landing-page-background {
    width: 100%;
    height: 100%;
    left: 0px;
    top: 243px;

    clip-path: ellipse(100% 90% at 40% 90%);
    background: linear-gradient(
        180deg,
        #ffde48 0%,
        rgba(255, 222, 72, 0.25) 100%
    );
}

.trophy-screen-background {
    background: conic-gradient(
            from 212.16deg at 55.14% 30.28%,
            #ffde49 0deg,
            #ffde49 32.78deg,
            #fde26b 98.42deg,
            rgba(255, 222, 73, 0.4) 119.93deg,
            rgba(255, 222, 73, 0.4) 155.33deg,
            rgba(255, 222, 73, 0.4) 189.75deg,
            rgba(255, 222, 73, 0.8) 211.13deg,
            #ffde49 238.07deg,
            #ffde49 254.63deg,
            #ffde49 275.08deg,
            #ffde49 328.88deg,
            #ffde49 360deg
        ),
        #ffffff;
}

#custom-keyboard-container input::-webkit-outer-spin-button,
#custom-keyboard-container input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
#custom-keyboard-container input[type="number"] {
    -moz-appearance: textfield;
}

.mic-toggle {
    position: relative;
    display: block;
    border-radius: 50%;
    background-color: #000fff;
    margin-bottom: 4rem;
}

.mic-toggle:after,
.mic-toggle:before {
    --pad: Orem;
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: var(--pad);
    left: var(--pad);
    right: var(--pad);
    bottom: var(--pad);
    border-radius: 50%;
}

.mic-toggle:after {
    --pad: -2rem;
    transition: 0.4s;
    background-color: rgba(0, 15, 255, 0.2);
}

.mic-toggle:before {
    --pad: -1rem;
    transition: 0.4s;
    background-color: rgba(0, 15, 255, 0.2);
}

.mic-toggle svg {
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 6rem;
}

.mic-toggle.is-recording:after {
    animation: smoothPadAfter 0.6s ease-in alternate-reverse forwards infinite;
}
.mic-toggle.is-recording:before {
    animation: smoothPadBefore 0.6s ease-in alternate-reverse forwards infinite;
}

@keyframes smoothPadAfter {
    0% {
        top: -2rem;
        left: -2rem;
        right: -2rem;
        bottom: -2rem;
    }

    100% {
        top: -1rem;
        left: -1rem;
        right: -1rem;
        bottom: -1rem;
    }
}

@keyframes smoothPadBefore {
    0% {
        top: -1rem;
        left: -1rem;
        right: -1rem;
        bottom: -1rem;
    }

    100% {
        top: -0.5rem;
        left: -0.5rem;
        right: -0.5rem;
        bottom: -0.5rem;
    }
}

#react-tag-input-container input::-webkit-outer-spin-button,
#react-tag-input-container input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#react-tag-input-container input[type="number"] {
    -moz-appearance: textfield;
}

@keyframes leftToRight {
    from {
        transform: translate(calc(-50% - var(--offset)));
    }
    to {
        transform: translate(0);
    }
}

@keyframes rightToLeft {
    to {
        transform: translate(calc(-50% - var(--offset)));
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
